import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  plansData: null,
};

const MasterData = createSlice({
  name: 'MasterSlice',
  initialState,
  reducers: {
    setPlansData: (state, action) => {
      state.plansData = action.payload;
    },
  },
});

export const { setPlansData } = MasterData.actions;

export default MasterData.reducer;
