import axios from 'src/utils/axios';
import { dispatch } from 'src/reducers/configureStore';
// import { setAuthData } from 'src/reducers/slices/AuthSLice';
import { errorToast, successToast } from 'src/shared/Toast';
import { setHideBeatLoader, setShowBeatLoader } from 'src/reducers/slices/AlertsSlice';
import { setPlansData } from 'src/reducers/slices/MasterSlice';
//import { setShowBeatLoader, setHideBeatLoader } from 'app/reducers/slices/AlertsSlice';
export const UserType = {
  SUPERADMIN: 1,
  COMPANY_ADMIN: 2,
  STANDARD_USER: 3,
  PRIMARY_ADMIN: 4,
};

// const adminFormatter = (admin) => {
//   return {
//     id: admin.id,
//     first_name: admin.first_name,
//     last_name: admin.last_name,
//     email: admin.email,
//     type:
//       admin.type === UserType.STANDARD_USER
//         ? 'Standard User'
//         : admin.type === UserType.PRIMARY_ADMIN
//         ? 'Primary Admin'
//         : null,
//     access_level: admin.type,
//     is_active: admin.is_active === true ? 'active' : 'inactive',
//     active_status: admin.is_active,
//   };
// };

// const adminsFormatter = (admins) => {
//   return admins.map((admin) => adminFormatter(admin));
// };
export const createAdmin = async (values) => {
  const payload = {
    first_name: values.firstName,
    last_name: values.lastName,
    email: values.email,
    password: values.password,
    password_confirmation: values.confirmPassword,
    is_active: values.isActive,
    type: values.accessLevel,
  };
  try {
    const { data } = await axios.post('/api/users/', payload);

    if (data?.status) {
      successToast(data.message);
      return true;
    } else {
      errorToast(data.errors[0].message);
    }
  } catch (error) {
    console.log('error', error);
    errorToast('Something went wrong!');
  }
};

export const ListUsers = async () => {
  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.get('/list-users');
    if (data?.status) {
      return data.data;
    } else {
      errorToast(data?.message);
    }
  } catch (error) {
    errorToast('Listing failed');

    console.error('Listing failer failed', error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const deleteUser = async (userId) => {
  try {
    dispatch(setShowBeatLoader());
    const response = await axios.delete(`/api/users/${userId}`);
    if (response?.data?.status) {
      successToast(response?.data?.message ?? 'Script Deleted Successfully');
      return true;
    } else {
      errorToast(response?.data?.message);
    }
  } catch (e) {
    console.error(e);
    errorToast('An error occurred while deleting the script');
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const editUser = async (values, id) => {
  console.log('valueseses', values);
  const payload = {
    userId: parseInt(id),
    name: values.name,
    phoneNumber: values.phone,
    status: values.isActive,
  };
  try {
    const { data } = await axios.post('/update-user', payload);

    if (data?.status) {
      successToast(data.message);
      return true;
    } else {
      errorToast(data.message);
    }
  } catch (error) {
    console.log('error', error);
    errorToast('Something went wrong!');
  }
};

export const paginationFormator = (data) => {
  return {
    totalPages: data?.total_page_count ?? 1,
    currentPage: data?.page ?? 1,
    pageSize: data?.page_size ?? 10,
  };
};

export const listPlans = async () => {
  // if (getState().MasterData.plansData.length) return;
  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.post('/list-plans');

    if (data?.status) {
      dispatch(setPlansData(data.data));
    } else {
      errorToast(data?.message);
    }
  } catch (error) {
    errorToast('Plans Listing failed');

    console.error('Listing failer failed', error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const listInvoices = async (id) => {
  const payload = {
    userId: parseInt(id),
  };
  try {
    const { data } = await axios.post('/list-invoices', payload);

    if (data?.status) {
      successToast(data.message);

      if (Array.isArray(data.data)) {
        return data.data;
      }
      return [];
    } else {
      errorToast(data.message);
    }
  } catch (error) {
    console.log('error', error);
    errorToast('Something went wrong!');
  }
};

export const generateKey = async (id, planId, PlanName) => {
  const payload = {
    userId: parseInt(id),
    planId: planId,
    planName: PlanName,
  };
  try {
    const { data } = await axios.post('/generate-api-key', payload);
    console.log('generate key data', data);

    if (data) {
      successToast(data.message);
      return data;
    } else {
      errorToast(data.message);
    }
  } catch (error) {
    console.log('error', error);
    errorToast('Something went wrong!');
  }
};

export const addUser = async (values) => {
  const payload = {
    name: values.name,
    email: values.email,
    phoneNumber: values.phone,
  };
  try {
    const { data } = await axios.post('/add-user', payload);

    if (data?.status) {
      successToast(data.message);
      return true;
    } else {
      errorToast(data.message);
    }
  } catch (error) {
    console.log('error', error);
    errorToast('Something went wrong!');
  }
};

export const getUsage = async (apiKey, startDate, endDate) => {
  const payload = {
    apiKey: apiKey,
    startDate: startDate,
    endDate: endDate,
  };
  console.log('paloaddddd', payload);
  try {
    const { data } = await axios.post('/getUsage', payload);

    if (data?.status) {
      successToast(data.message);
      return data.data;
    } else {
      errorToast(data.message);
    }
  } catch (error) {
    console.log('error', error);
    errorToast('Something went wrong!');
  }
};

export const generateInvoices = async (userId, startDate, endDate, amount, quantity) => {
  const payload = {
    userId: userId,
    startDate: startDate,
    endDate: endDate,
    oneTimeItems: [
      {
        name: 'Api usage',
        amount: amount,
        quantity: quantity,
      },
    ],
  };
  console.log('paloaddddd', payload);
  try {
    const { data } = await axios.post('/generate-invoice', payload);
    console.log('invoicessssssss', data);

    if (data?.status) {
      successToast(data.message);
      return data.data;
    } else {
      errorToast(data.message);
    }
  } catch (error) {
    console.log('error', error);
    errorToast('Something went wrong!');
  }
};
