import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Box, Typography, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate, useParams } from 'react-router';
import { addUser } from 'src/ApiCalls/AdminApiCalls';
import { LoadingButton } from '@mui/lab';

const AddUserForm = () => {
  const navigate = useNavigate();
  const { id: userId } = useParams();
  const [loading, setLoading] = useState(false);
  const handleForm = async (values) => {
    let response;
    setLoading(true);
    response = await addUser(values);
    setLoading(false);
    if (response) {
      navigate('/admin-management');
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().required('Phone Number is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      planname: '',
      phone: '',
      plan_id: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleForm(values);
    },
  });

  const handleReset = useCallback(() => {
    navigate('/admin-management');
  }, [navigate]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            bgcolor: '#ffffff',
            marginTop: { xs: 4, sm: 8 },
            padding: { xs: 3, sm: 6 },
            borderRadius: 6,
            position: 'relative',
          }}
        >
          <Typography variant="h3"> {!userId ? 'Add user' : 'Update User'}</Typography>
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ color: 'black', fontWeight: '450', mb: 1 }}>Name:</Typography>
              <TextField
                fullWidth
                id="name"
                name="name"
                variant="outlined"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography sx={{ color: 'black', fontWeight: '450', mb: 1 }}>Email:</Typography>
              <TextField
                fullWidth
                id="email"
                name="email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Typography sx={{ color: 'black', fontWeight: '450', mb: 1 }}>Plan Name:</Typography>
              <TextField
                fullWidth
                id="planname"
                name="planname"
                variant="outlined"
                value={formik.values.planname}
                onChange={formik.handleChange}
                error={formik.touched.planname && Boolean(formik.errors.planname)}
                helperText={formik.touched.planname && formik.errors.planname}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <Typography sx={{ color: 'black', fontWeight: '450', mb: 1 }}>
                Phone Number:
              </Typography>
              <TextField
                fullWidth
                id="phone"
                name="phone"
                variant="outlined"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Typography sx={{ color: 'black', fontWeight: '450', mb: 1 }}>Plan Id</Typography>
              <TextField
                fullWidth
                id="plan_id"
                name="plan_id"
                variant="outlined"
                value={formik.values.plan_id}
                onChange={formik.handleChange}
                error={formik.touched.plan_id && Boolean(formik.errors.plan_id)}
                helperText={formik.touched.plan_id && formik.errors.plan_id}
              />
            </Grid> */}
          </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Button
            variant="outlined"
            onClick={handleReset}
            size="large"
            sx={{ mr: 2, bgcolor: 'white', color: 'black', border: 'white' }}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            startIcon={<CheckIcon />}
            color="primary"
            loading={loading}
          >
            Add User
          </LoadingButton>
        </Box>
      </form>
    </>
  );
};

export default AddUserForm;
