import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import AuthGuard from 'src/views/authentication/auth/AuthGuard';
import AddUserForm from 'src/views/besiadminmanagement/AddUserForm';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));
const PlanGrid = Loadable(lazy(() => import('../views/Plans/PlansGrid')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));
const BesiAdminManagement = Loadable(
  lazy(() => import('../views/besiadminmanagement/BesiAdminManagement')),
);
const AddBesiAdminManagement = Loadable(
  lazy(() => import('../views/besiadminmanagement/AddBesiAdminManagement')),
);

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const AuthorizationError = Loadable(
  lazy(() => import('../views/authentication/AuthorizationError')),
);
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const ForgetPassword = Loadable(lazy(() => import('../views/authentication/ForgetPassword')));
const OtpConfirmation = Loadable(lazy(() => import('../views/authentication/OtpConfirmation')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/ResetPassword')));

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/admin-management" /> },
      { path: '/dashboard', exact: true, element: <Dashboard /> },
      { path: '/admin-management', exact: true, element: <BesiAdminManagement /> },
      { path: '/admin-management/add-admin', element: <AddBesiAdminManagement /> },
      { path: '/admin-management/edit-admin/:id', element: <AddBesiAdminManagement /> },
      { path: '/addUserForm', element: <AddUserForm /> },
      { path: '/plans', element: <PlanGrid /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '/auth/register', element: <Register /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/forgetpassword', element: <ForgetPassword /> },
      { path: '/auth/otpconfirmation', element: <OtpConfirmation /> },
      { path: '/auth/resetpassword', element: <ResetPassword /> },
    ],
  },
  { path: '*', element: <Error /> },
  {
    path: '/unauthorized',
    element: (
      <AuthGuard>
        <AuthorizationError />
      </AuthGuard>
    ),
  },
];

export default Router;
